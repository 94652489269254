import React from "react"
import { Link } from "gatsby"

const PortfolioLink = ({ post }) => {
  // Check if post is defined before accessing its properties
  if (!post) {
    return null; // Return null if post is undefined
  }

  return (
    <li className="project-item  active" data-filter-item="" data-category={post.category}>
      <a target="_blank" href={post.external}>
        <figure className="project-img">
          <div className="project-item-icon-box">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path className="fa-primary" d="M288 32c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32V192c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3L246.6 310.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L402.7 64H320c-17.7 0-32-14.3-32-32z"/><path className="fa-secondary" d="M0 112C0 67.8 35.8 32 80 32H192c17.7 0 32 14.3 32 32s-14.3 32-32 32H80c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16H400c8.8 0 16-7.2 16-16V320c0-17.7 14.3-32 32-32s32 14.3 32 32V432c0 44.2-35.8 80-80 80H80c-44.2 0-80-35.8-80-80V112z"/></svg>
          </div>
          {!!post.thumbnail && (
            <img src={post.thumbnail} alt={`${post.title} - Featured image`} width="1280" height="800" />
          )}
        </figure>
      </a>
      <Link to={post.path}>
        <h3 className="project-title">{post.title}</h3>
          <p className="project-category">{post.category}</p>
      </Link>
    </li>
  )
}

export default PortfolioLink
