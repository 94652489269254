import React, { useState, useEffect } from "react";
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from "../components/layout";
import PortfolioLink from "../components/portfolio-link"; // Corrected component import
import HeroHeader from "../components/heroHeader";

const PortfolioPage = ({
  data: {
    site,
    allMarkdownRemark: { edges },
  },
}) => {
  const [portfolioItemCount, setPortfolioItemCount] = useState(6);
  const [isLoading, setIsLoading] = useState(false);

  const PortfolioItems = edges
    .filter(edge => !!edge.node.frontmatter.date)
    .slice(0, portfolioItemCount)
    .map(edge => <PortfolioLink key={edge.node.id} post={edge.node.frontmatter} />);

  const loadMorePortfolioItems = () => {
    setIsLoading(true);
    setTimeout(() => {
      setPortfolioItemCount(prevCount => prevCount + 6);
      setIsLoading(false);
    }, 100);
  };

  useEffect(() => {
    const initializeCustomSelect = () => {
      const select = document.querySelector("[data-select]");
      const selectItems = document.querySelectorAll("[data-select-item]");
      const selectValue = document.querySelector("[data-selecct-value]");
      const filterBtn = document.querySelectorAll("[data-filter-btn]");
      const elementToggleFunc = (elem) => {
        elem.classList.toggle("active");
      };

      select.addEventListener("click", function () {
        elementToggleFunc(this);
      });

      for (let i = 0; i < selectItems.length; i++) {
        selectItems[i].addEventListener("click", function () {
          let selectedValue = this.innerText.toLowerCase();
          selectValue.innerText = this.innerText;
          elementToggleFunc(select);
          filterFunc(selectedValue);
        });
      }

      const filterItems = document.querySelectorAll("[data-filter-item]");

      const filterFunc = function (selectedValue) {
        for (let i = 0; i < filterItems.length; i++) {
          if (selectedValue === "all") {
            filterItems[i].classList.add("active");
          } else if (selectedValue === filterItems[i].dataset.category.toLowerCase()) {
            filterItems[i].classList.add("active");
          } else {
            filterItems[i].classList.remove("active");
          }
        }
      };

      let lastClickedBtn = filterBtn[0];

      for (let i = 0; i < filterBtn.length; i++) {
        filterBtn[i].addEventListener("click", function () {
          let selectedValue = this.innerText.toLowerCase();
          selectValue.innerText = this.innerText;
          filterFunc(selectedValue);

          lastClickedBtn.classList.remove("active");
          this.classList.add("active");
          lastClickedBtn = this;
        });
      }
    };

    initializeCustomSelect();
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Portfolio — {site.siteMetadata.title}</title>
        <meta name="description" content={site.siteMetadata.description} />
      </Helmet>
      <header>
        <h2 className="h2 article-title">Portfolio</h2>
      </header>
      <section className="projects">
        <ul className="filter-list">
          <li className="filter-item">
            <button className="active" data-filter-btn="">All</button>
          </li>
          <li className="filter-item">
            <button data-filter-btn="">Web Design</button>
          </li>
          <li className="filter-item">
            <button data-filter-btn="">Web Development</button>
          </li>
        </ul>
        <div className="filter-select-box">
          <button className="filter-select" data-select="">
            <div className="select-value" data-selecct-value="">Select category</div>
            <div className="select-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M112 184l144 144 144-144" stroke="currentColor"></path>
              </svg>
            </div>
          </button>
          <ul className="select-list">
            <li className="select-item">
              <button data-select-item="">All</button>
            </li>
            <li className="select-item">
              <button data-select-item="">Web Design</button>
            </li>
            <li className="select-item">
              <button data-select-item="">Web Development</button>
            </li>
          </ul>
        </div>
        <ul className="project-list">
          {PortfolioItems}
        </ul>
        {!isLoading && portfolioItemCount < edges.length && (
          <div className="load-more">
            <button
              className={`btn ${isLoading ? "hide" : ""}`}
              onClick={loadMorePortfolioItems}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path className="fa-primary" d="M239 345c9.4 9.4 24.6 9.4 33.9 0L377 241c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-87 87-87-87c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 345z"/><path className="fa-secondary" d="M256 512A256 256 0 1 1 256 0a256 256 0 1 1 0 512zM377 241c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-87 87-87-87c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 345c9.4 9.4 24.6 9.4 33.9 0L377 241z"/></svg>
              {isLoading ? "Loading..." : "Load More"}
            </button>
          </div>
        )}
      </section>
    </Layout>
  );
};

export default PortfolioPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { template: { in: ["PortfolioItem"] } }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            thumbnail
            category
            external
          }
        }
      }
    }
  }
`;
